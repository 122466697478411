import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import styles from "./formStyles.module.css";
import {validationSchema} from "../validations/validationSchema"

import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";

import TextArea from "../../../reactComponents/textArea/TextArea";
const ComComerciales = ({ organization, flatRates, reqInvoicesData, informPricesData, informTelcoPricesData, 
  savingCalcData, savingTelcoCalcData, accDeacData, accUnlockData, welcomeMailData,pdfDownloadEmail,
  pdfSimulationEmail,  luz_2xExcel, luz_3xExcel, gasExcel, dualExcel, selectedSCalcExcel, failedInvoicesExcel,
  rPlansSCalcExcel, current_user_roles, selectedTemplates }) => {
    const formik = useFormik({
      initialValues: {
        organization: {
          r_plan_info_display_fmt_inform_pr: 
          organization.r_plan_info_display_fmt_inform_pr
        },
      },
      validationSchema: validationSchema,
    });
  const [htmlCorreo, setHtmlCorreo] = useState(
    organization.invoice_request_email_template
  );

  const [htmlFooter, setHtmlFooter] = useState(
    organization.savings_email_footer
  );

  const [htmlHeader, setHtmlHeader] = useState(
    organization.savings_email_header
  );

  const handleHtmlCorreoChange = (e) => {
    setHtmlCorreo(e.target.value);
  };

  const handleHtmlFooterChange = (e) => {
    setHtmlFooter(e.target.value);
  };

  const handleHtmlHeaderChange = (e) => {
    setHtmlHeader(e.target.value);
  };

  //selects

  const getTemplateName = (templateId, templateData) => {
    const matchingTemplate = templateData.find(
      (item) => item.id === templateId
    );
    return matchingTemplate ? matchingTemplate.name : "";
  };

  const [reqInvoice, setReqInvoice] = useState(() =>
      selectedTemplates?.request_invoice?.[0]?.id ?? null
  );
  const [infPrecios, setInfPrecios] = useState(() =>
    selectedTemplates?.inform_prices?.[0]?.id ?? null
  );
  const [telcoInfPrecios, setTelcoInfPrecios] = useState(() =>
    selectedTemplates?.tlc_inform_prices?.[0]?.id ?? null
  );
  const [savingCalc, setSavingCalc] = useState(() =>
    selectedTemplates?.saving_calculation?.[0]?.id ?? null
  );
  const [telcoSavingCalc, setTelcoSavingCalc] = useState(() =>
    selectedTemplates?.tlc_saving_calculation?.[0]?.id ?? null
  );
  const [deactUser, setDeactUser] = useState(() =>
    selectedTemplates?.account_desactivation?.[0]?.id ?? null
  );
  const [accUnlock, setAccUnlock] = useState(() =>
    selectedTemplates?.account_unlock?.[0]?.id ?? null
  );
  const [welcomeUser, setWelcomeUser] = useState(() =>
    selectedTemplates?.welcome_mail?.[0]?.id ?? null
  );
  

  const [pdfDownload, setPdfDownload] = useState(() =>
    selectedTemplates?.pdf_download?.[0]?.id ?? null
  );
  const [pdfSimulation, setPdfSimulation] = useState(() =>
    selectedTemplates?.pdf_simulation?.[0]?.id ?? null
  );

  const [luz_2x, setLuz_2x] = useState(()=> selectedTemplates?.luz_2x?.[0]?.id ?? null);
  const [luz_3x, setLuz_3x] = useState(()=> selectedTemplates?.luz_3x?.[0]?.id ?? null);
  const [gas, setGas] = useState(()=> selectedTemplates?.gas?.[0]?.id ?? null);
  const [dual, setDual] = useState(()=> selectedTemplates?.dual?.[0]?.id ?? null);
  const [selectedSCalc, setSelectedSCalc] = useState(()=> selectedTemplates?.selected_s_calc?.[0]?.id ?? null);
  const [failedInvoices, setFailedInvoices] = useState(()=> selectedTemplates?.failed_invoices?.[0]?.id ?? null);
  const [rPlansSCalc, setRPlansSCalc] = useState(()=> selectedTemplates?.r_plans_s_calc?.[0]?.id ?? null);

  useEffect(() => {
    setReqInvoice(
      selectedTemplates?.request_invoice?.[0]?.id ?? null
    );
    setInfPrecios(
      selectedTemplates?.inform_prices?.[0]?.id ?? null
    );
    setTelcoInfPrecios(
      selectedTemplates?.tlc_inform_prices?.[0]?.id ?? null
    );
    setSavingCalc(
      selectedTemplates?.saving_calculation?.[0]?.id ?? null
    );
    setTelcoSavingCalc(
      selectedTemplates?.tlc_saving_calculation?.[0]?.id ?? null
    );
    setDeactUser(
      selectedTemplates?.account_desactivation?.[0]?.id ?? null
    );
    setAccUnlock(
      selectedTemplates?.account_unlock?.[0]?.id ?? null
    );
    setWelcomeUser(
      selectedTemplates?.welcome_mail?.[0]?.id ?? null
    );
    setPdfDownload(
      selectedTemplates?.pdf_download?.[0]?.id ?? null
    );
    setPdfSimulation(
      selectedTemplates?.pdf_simulation?.[0]?.id ?? null
    );
    setLuz_2x(selectedTemplates?.luz_2x?.[0]?.id ?? null);
    setLuz_3x(selectedTemplates?.luz_3x?.[0]?.id ?? null);
    setGas(selectedTemplates?.gas?.[0]?.id ?? null);
    setDual(selectedTemplates?.dual?.[0]?.id ?? null);
    setSelectedSCalc(selectedTemplates?.selected_s_calc?.[0]?.id ?? null);
    setFailedInvoices(selectedTemplates?.failed_invoices?.[0]?.id ?? null);
    setRPlansSCalc(selectedTemplates?.r_plans_s_calc?.[0]?.id ?? null);
  }, [reqInvoice, reqInvoicesData,
    infPrecios, informPricesData, 
    savingCalc, savingCalcData,
    telcoSavingCalc, savingTelcoCalcData, 
    telcoInfPrecios, informTelcoPricesData,
    deactUser, accDeacData,
    accUnlock, accUnlockData,
    welcomeUser, welcomeMailData,
    pdfDownload, pdfDownloadEmail,

    pdfSimulation, pdfSimulationEmail,
    luz_2x, luz_2xExcel,
    luz_3x, luz_3xExcel,
    gas, gasExcel,
    dual, dualExcel,
    selectedSCalc, selectedSCalcExcel,
    failedInvoices, failedInvoicesExcel,
    rPlansSCalc, rPlansSCalcExcel,
  ]);

  const flatRatesData = flatRates.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const findValueMatch = (array, valuePlaceholder) => {
    let matchingName = null;

    array.forEach((option) => {
      if (option.id === valuePlaceholder) {
        valuePlaceholder = option.id;
        matchingName = option.name;
      }
    });
    return matchingName;
  };

  const flatRatesString = findValueMatch(
    flatRatesData,
    organization.allow_compare_flat_rates
  );

  const updateSolicInvoice = [{ id: null, name: null, plantilla: "Plantilla por defecto" }, ...reqInvoicesData];

  const updateInformPrices = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...informPricesData,
  ]; 

  const updateInformTelcoPrices = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...informTelcoPricesData,
  ];

  const calcSavings = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...savingCalcData,
  ];

  const calcTelcoSavings = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...savingTelcoCalcData,
  ];

  const deactUserr = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...accDeacData,
  ];

  const unlockAccount = [
  { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...accUnlockData,
  ];
  const welcomeUserEm = [
  { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...welcomeMailData,
  ];
  const downloadPdf = [
  { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...pdfDownloadEmail,
  ];
  const simulatePdf = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
    ...pdfSimulationEmail,
  ];
  const luz2xExcel = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...luz_2xExcel,
  ];
  const luz3xExcel = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...luz_3xExcel,
  ];
  const gasExcelTempl = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...gasExcel,
  ];
  const dualExcelTempl = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...dualExcel,
  ];
  const selectedSCalcExcelTempl = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...selectedSCalcExcel,
  ];
  const failedInvoicesExcelTempl = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...failedInvoicesExcel,
  ];
  const rPlansSCalcExcelTempl = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...rPlansSCalcExcel,
  ];

  const foundItemName = (array, value) => {
    let matchingName = null;
    array.forEach((option) => {
      if (option.id === value) {
        matchingName = option.name;
      }
    });
    return matchingName;
  }

  return (
    <>
      <h3 className={styles.title}> Comunicaciones comerciales</h3>
      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Solicitud de facturas'"
          data={updateSolicInvoice}
          placeholder={
            reqInvoice === null
              ? "Plantilla por defecto"
              : foundItemName(
                  updateSolicInvoice,
                  reqInvoice
                )
          }
          selectId="organization_request_invoice_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[reqInvoice]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Informar precios'"
          data={updateInformPrices}
          placeholder={
            infPrecios === null
              ? "Plantilla por defecto"
              : foundItemName(
                  updateInformPrices,
                  infPrecios
                )
          }
          selectId="organization_inform_prices_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[infPrecios]}
        />
      </div>
      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Cálculo de ahorro'"
          data={calcSavings}
          placeholder={
            savingCalc === null
              ? "Plantilla por defecto"
              : foundItemName(
                  calcSavings,
                  savingCalc
                )
          }
          selectId="organization_saving_calculation_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[savingCalc]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Desactivación de usuario'"
          data={deactUserr}
          placeholder={
            deactUser === null
              ? "Plantilla por defecto"
              : foundItemName(
                  deactUserr,
                  deactUser
                )
          }
          selectId="organization_account_desactivation_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[deactUser]}
        />
      </div>
      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Desbloqueo de cuenta'"
          data={unlockAccount}
          placeholder={
            accUnlock === null
              ? "Plantilla por defecto"
              : foundItemName(
                  unlockAccount,
                  accUnlock
                )
          }
          selectId="organization_account_unlock_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[accUnlock]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Bienvenida de usuario'"
          data={welcomeUserEm}
          placeholder={
            welcomeUser === null
              ? "Plantilla por defecto"
              : foundItemName(
                  welcomeUserEm,
                  welcomeUser
                )
          }
          selectId="organization_welcome_mail_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[welcomeUser]}
        />
      </div>

      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Descargar PDF'"
          data={downloadPdf}
          placeholder={
            pdfDownload === null
              ? "Plantilla por defecto"
              : foundItemName(
                  downloadPdf,
                  pdfDownload
                )
          }
          selectId="organization_pdf_download_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[pdfDownload]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Simular PDF'"
          data={simulatePdf}
          placeholder={
            pdfSimulation === null
              ? "Plantilla por defecto"
              : foundItemName(
                  simulatePdf,
                  pdfSimulation
                )
          }
          selectId="organization_pdf_simulation_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[pdfSimulation]}
        />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Luz 2x'"
            data={luz2xExcel}
            placeholder={
              luz_2x === null
                ? "Plantilla por defecto"
                : foundItemName(
                  luz2xExcel,
                  luz_2x
                  )
            }
            selectId="organization_luz_2x_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[luz_2x]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Luz 3x'"
            data={luz3xExcel}
            placeholder={
              luz_3x === null
                ? "Plantilla por defecto"
                : foundItemName(
                  luz3xExcel,
                  luz_3x
                  )
            }
            selectId="organization_luz_3x_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[luz_3x]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Gas'"
            data={gasExcelTempl}
            placeholder={
              gas === null
                ? "Plantilla por defecto"
                : foundItemName(
                  gasExcelTempl,
                  gas
                  )
            }
            selectId="organization_gas_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[gas]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Dual'"
            data={dualExcelTempl}
            placeholder={
              dual === null
                ? "Plantilla por defecto"
                : foundItemName(
                  dualExcelTempl,
                  dual
                  )
            }
            selectId="organization_dual_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[dual]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Ahorros calculados seleccionados'"
            data={selectedSCalcExcelTempl}
            placeholder={
              selectedSCalc === null
                ? "Plantilla por defecto"
                : foundItemName(
                  selectedSCalcExcelTempl,
                    selectedSCalc
                  )
            }
            selectId="organization_selected_s_calc_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[selectedSCalc]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamientos inválidos'"
            data={failedInvoicesExcelTempl}
            placeholder={
              failedInvoices === null
                ? "Plantilla por defecto"
                : foundItemName(
                  failedInvoicesExcelTempl,
                    failedInvoices
                  )
            }
            selectId="organization_failed_invoices_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[failedInvoices]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Ahorros de las tarifas seleccionadas'"
            data={rPlansSCalcExcelTempl}
            placeholder={
              rPlansSCalc === null
                ? "Plantilla por defecto"
                : foundItemName(
                  rPlansSCalcExcelTempl,
                    rPlansSCalc
                  )
            }
            selectId="organization_r_plans_s_calc_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[rPlansSCalc]}
          />
          <div className={styles.space}></div>
        </div>
        <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas 'Informar precios Telco'"
          data={updateInformTelcoPrices}
          placeholder={
            telcoInfPrecios === null
              ? "Plantilla por defecto"
              : foundItemName(
                updateInformTelcoPrices,
                  telcoInfPrecios
                )
          }
          selectId="organization_tlc_inform_prices_email_template_id"
          selectName="organization[external_templates][]"
          dataSelected={[telcoInfPrecios]}
        />
        <div className={styles.space}></div>
        <Select
            multiSelect={false}
            searchBar={true}
            name="Plantillas de 'Cálculo de ahorro telco'"
            data={calcTelcoSavings}
            placeholder={
              telcoSavingCalc === null
                ? "Plantilla por defecto"
                : foundItemName(
                    calcTelcoSavings,
                    telcoSavingCalc
                  )
            }
            selectId="organization_tlc_saving_calculation_email_template_id"
            selectName="organization[external_templates][]"
            dataSelected={[telcoSavingCalc]}
          />
          <div className={styles.space}></div>
        </div>

      <TextArea
        labelText="HTML Correo 'Solicitud Factura'"
        name="organization[invoice_request_email_template]"
        id="organization_invoice_request_email_template"
        labelClassName={styles.headerColor}
        smallText=""
        defaultValue={htmlCorreo}
        onChange={(e) => handleHtmlCorreoChange(e)}
      />
      <TextArea
        labelText="HTML Footer 'Envío Ahorro"
        name="organization[savings_email_footer]"
        id="organization_savings_email_footer"
        labelClassName={styles.headerColor}
        smallText=""
        defaultValue={htmlFooter}
        onChange={(e) => handleHtmlFooterChange(e)}
      />
      <TextArea
        labelText="HTML Header 'Envío Ahorro'"
        name="organization[savings_email_header]"
        id="organization_savings_email_header"
        labelClassName={styles.headerColor}
        smallText=""
        defaultValue={htmlHeader}
        onChange={(e) => handleHtmlHeaderChange(e)}
      />
      <Select
        multiSelect={false}
        searchBar={true}
        name="Gestión facturas tipo: 'Tarifa Plana'"
        data={flatRatesData}
        placeholder={foundItemName(
          flatRatesData,
          organization.allow_compare_flat_rates
        )}
        dataSelected={organization.allow_compare_flat_rates}
        selectId="organization_allow_compare_flat_rates"
        selectName="organization[allow_compare_flat_rates]"
      />

      {(current_user_roles.some((rol) => rol.name === "Admin")) &&
        <>
          <Input
            label={
              <>
              Formato de visualización de datos de tarifario en informar precios <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            required="required"
            name="organization[r_plan_info_display_fmt_inform_pr]"
            id="organization_r_plan_info_display_fmt_inform_pr"
            placeholder={organization.r_plan_info_display_fmt_inform_pr}
            value={formik.values.organization.r_plan_info_display_fmt_inform_pr}
            onChange={formik.handleChange}
            error={formik.errors.organization?.r_plan_info_display_fmt_inform_pr}
            touched={formik.touched.organization?.r_plan_info_display_fmt_inform_pr}
          />
        </>
      }
    </>
  );
};
export default ComComerciales;
